import React, { useEffect } from "react";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import { useAssignPersonMutation } from "../../../../generated/graphql";

import PrimaryButton from "../../../atoms/PrimaryButton";
import SecondaryButton from "../../../atoms/SecondaryButton";
import { useHistory } from "react-router";
import { usePersonCreateContext } from "../provider";
import { STEPS } from "../reducer";

interface IPersonAssignMatch {
  handleClose: () => void;
}

const PersonAssignMatch = ({ handleClose }: IPersonAssignMatch) => {
  const { personActivity, setStep } = usePersonCreateContext();
  const { push } = useHistory();
  const [assignPerson] = useAssignPersonMutation();

  let match: any;
  if (personActivity && personActivity.matches && personActivity.matches.length === 1) {
    match = personActivity.matches[0];
  }

  useEffect(() => {
    if (!match?.id) {
      setStep(STEPS.ASSIGN_OR_CREATE);
    } else {
      setStep(STEPS.ASSIGN_MATCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.id]);

  if (!match) {
    return null;
  }

  const { id, name, age, ageGuessed } = match;

  if (!id) {
    throw Error();
  }
  // TODO: Determine correctly!
  const isPast = false;

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        width={1}
      >
        <Typography variant="h1" align="center">
          {isPast ? "War" : "Ist"} das {name}
          {age ? `, ${age}${ageGuessed ? " (geschätzt)" : ""} Jahre, ` : " "}
          am Telefon?
        </Typography>
      </Box>
      <Box display="flex" mt={5}>
        <SecondaryButton onClick={() => setStep(STEPS.ASSIGN_OR_CREATE)}>
          Nein, andere Person zuweisen
        </SecondaryButton>
        <Box mx={1} />
        <PrimaryButton
          onClick={async () => {
            await assignPerson({
              variables: {
                id: personActivity.id,
                personId: id,
              },
            });

            handleClose();
            push(`/person/${id}`);
          }}
        >
          Ja, zuweisen
        </PrimaryButton>
      </Box>
    </>
  );
};

export default PersonAssignMatch;
