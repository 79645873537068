import { makeStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    maxHeight: 225,
    overflowY: "auto" as "auto",
  },
  chip: {
    fontWeight: theme.typography.fontWeightMedium,
    justifyContent: "start" as "start",
    marginRight: theme.spacing(2),
    width: theme.spacing(8),
  },
  mental: {
    background: "none" as "none",
    fill: theme.palette.common.white,
    height: theme.spacing(2),
    marginLeft: theme.spacing(1.5),
    width: theme.spacing(2),
  },
  item: {
    display: "flex",
    height: theme.spacing(7),

    "&:hover": {
      backgroundColor: "#F8FAFB",
    },
  },
  ripple: {
    backgroundColor: theme.palette.primary.light,
  },
  secondary: {
    width: theme.spacing(35),
  },
}));

export default useStyles;
