import React, { FC, useState } from "react";

import { Field, FormikProps, FormikValues, FormikActions } from "formik";
import { TextField } from "formik-material-ui";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

import GenderSelect from "../../molecules/GenderSelect";
import {
  BusinessObject,
  useAddClientMutation,
  useAddPersonMutation,
  useCreateBusinessObjectMutation,
  useUpdateOrCreateContactMutation,
  Person,
  useBusinessObjectQuery,
  useBusinessObjectsQuery,
  useBusinessObjectsLazyQuery,
  useAccountLazyQuery,
  AccountType,
  useUpdatePersonExtrasMutation,
} from "../../../generated/graphql";
import AccountSelect from "../../fields/AccountSelect";
import BusinessCategorySelect from "../../fields/BusinessCategorySelect";
import ClientTypeSelect from "../../fields/ClientTypeSelect";

import CreateForm, {
  PersonValidation,
  ClientValidation,
  BusinessValidation,
  initialBusinessValues,
  initialClientValues,
  initialPersonValues,
  initialAccountValues,
  GoogleAccountValidation,
  LifeWorkAccountValidation,
} from "./form";
import { usePersonCreateContext } from "../../organisms/PersonCreateDialog/provider";
import { STEPS, TYPES } from "../../organisms/PersonCreateDialog/reducer";
import PrimaryButton from "../../atoms/PrimaryButton";
import SecondaryButton from "../../atoms/SecondaryButton";
import AsyncSearch from "../../molecules/AsyncSearch";
import PhoneCountrySelect from "../../molecules/PhoneCountrySelect";
import ClientAge from "../ClientAge";
import InformationSourceSelect from "../../molecules/InformationSourceSelect";

const PersonCreateForm = () => {
  const { step, type } = usePersonCreateContext();

  switch (step) {
    case STEPS.TYPE:
      return <PersonTypeSelect />;
    case STEPS.DETAILS:
      switch (type) {
        case TYPES.BUSINESS:
          return <BusinessForm />;
        case TYPES.PERSON:
          return <PersonForm />;
        case TYPES.CLIENT:
          return <ClientForm />;
        case TYPES.UNDEFINED:
          return null;
      }
  }
};

/**
 *
 */
const PersonTypeSelect: FC = () => {
  const { setType } = usePersonCreateContext();

  return (
    <Box>
      <Typography variant="h1" align="center">
        Um was für einen Kontakt handelt es sich?
      </Typography>
      <Box display="flex" mt={5}>
        <PrimaryButton onClick={() => setType(TYPES.CLIENT)}>Klient</PrimaryButton>
        <Box mx={1} />
        <SecondaryButton onClick={() => setType(TYPES.PERSON)}>Privatperson</SecondaryButton>
        <Box mx={1} />
        <SecondaryButton onClick={() => setType(TYPES.BUSINESS)}>Geschäftskontakt</SecondaryButton>
      </Box>
    </Box>
  );
};

/**
 *
 */
const PersonForm: FC = () => {
  const { onClose } = usePersonCreateContext();
  const [addPerson] = useAddPersonMutation();

  const onSubmit = async (values: FormikValues, { setSubmitting }: FormikActions<FormikValues>) => {
    const { countryCode, areaCode, number, ...person } = values;

    const phones = areaCode && number ? [{ areaCode, number, countryCode }] : [];

    try {
      const { data } = await addPerson({
        variables: {
          person: {
            ...person,
            phones,
          },
        },
      });

      if (data?.addPerson?.id) {
        onClose(`/person/${data.addPerson.id}`);
      } else {
        onClose(undefined, true);
      }
    } catch (error) {
      console.error(error);
      onClose(undefined, true);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CreateForm
      title="Privatperson anlegen"
      onSubmit={onSubmit}
      initialValues={initialPersonValues}
      validationSchema={PersonValidation}
    >
      {() => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field name="firstName" component={TextField} label="Vorname" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field name="lastName" component={TextField} label="Nachname" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <GenderSelect fullWidth margin="normal" />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field name="alias" label="Alias" component={TextField} />
                </FormControl>
              </Grid>
            </Grid>
            <Box mt={2} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PhoneCountrySelect name="countryCode" />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <Field name="areaCode" component={TextField} label="Vorwahl" />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <Field name="number" component={TextField} label="Nummer" />
                </FormControl>
              </Grid>
            </Grid>
          </>
        );
      }}
    </CreateForm>
  );
};

/**
 *
 */
const ClientForm: FC = () => {
  const { onClose } = usePersonCreateContext();
  const [addClient] = useAddClientMutation();  
  const [submitted, setSubmitted] = useState(false)
  const [currentAccount, setCurrentAccount] = useState({} as any);
  const [personId, setPersonId] = useState(-1);
  const [updatePersonExtras] = useUpdatePersonExtrasMutation();

  

  const updateExtras = (personId: any, informationSource = '', hrBusiness = '') => {
    
  };

  const onSubmit = async (values: FormikValues, { setSubmitting }: FormikActions<FormikValues>) => {
    const {
      ageGuessed,
      initialAge,
      birthDate,
      countryCode,
      areaCode,
      number,
      account,
      clientType,
      informationSource,
      hrBusiness,
      source,
      ...person
    } = values;

    const phones = areaCode && number ? [{ areaCode, number, countryCode }] : [];

    if (!submitted) {
      try {
        const { data } = await addClient({
          variables: {
            client: {
              accountId: parseInt(account.value, 10),
              clientType,
              person: {
                ...person,
                phones,
                ageGuessed,
                initialAge,
                birthDate,
              },
            },
          },
        });


        if (data?.addClient?.person?.id) {
          if (data?.addClient?.account?.type === AccountType.lifeWorks) {
            setCurrentAccount(data.addClient?.account);
            setSubmitted(true);
            setPersonId(data?.addClient?.person?.id);
          } else {
            onClose(`/person/${data.addClient.person.id}`);
          }
        } else {
          onClose(undefined, true);
        }
      } catch (error) {
        console.error(error);
        onClose(undefined, true);
      } finally {
        setSubmitting(false);
      }
    }
    else {
      const data = await updatePersonExtras({
        variables: {
          personId: personId,
          personExtraInput: {
            informationSource: informationSource,
            hrBusiness: hrBusiness,
            source: source,
          },
        },
      });
      onClose(`/person/${personId}`);
    }
  };
  
  if(!submitted) {
  return (
    <CreateForm
      title="Klient anlegen"
      onSubmit={onSubmit}
      initialValues={initialClientValues}
      validationSchema={ClientValidation}
    >
      {({
        setFieldValue,
        setTouched,
        errors,
        touched,
        validateForm,
        values,
      }: FormikProps<FormikValues>) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field name="firstName" component={TextField} label="Vorname" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field name="lastName" component={TextField} label="Nachname" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <GenderSelect fullWidth margin="normal" />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field name="alias" label="Alias" component={TextField} />
                </FormControl>
              </Grid>
              <ClientAge
                setFieldValue={setFieldValue}
                errors={errors}
                validateForm={validateForm}
                values={values}
              />
            </Grid>
            <Box mt={2} />
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PhoneCountrySelect name="countryCode" />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <Field name="areaCode" component={TextField} label="Vorwahl" />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <Field name="number" component={TextField} label="Nummer" />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <AccountSelect
                  formError={!!errors.account && !!touched.account}
                  onBlur={() => {
                    setTouched({ ...touched, account: true });
                  }}
                  onChange={value => {
                    setFieldValue("account", value);
                  }}
                  value={values.account || { value: null }}
                />
              </Grid>
              <Grid item xs={6}>
                <ClientTypeSelect />
              </Grid>
            </Grid>
          </>
        );
      }}
    </CreateForm>
  );
    } else {            
      const isGoogleAccount = currentAccount.name.toUpperCase().includes('GOOGLE');
      const title = `Zusätzliche Klienteninformationen (${isGoogleAccount ? 'Google' : 'LifeWorks'})`;
      const validation = isGoogleAccount ? GoogleAccountValidation : LifeWorkAccountValidation
      return (
        <CreateForm
          title={title}
          onSubmit={onSubmit}
          initialValues={initialAccountValues}
          validationSchema={GoogleAccountValidation}
          hasBackButton={false}
        >
          {({
            setFieldValue,
            setTouched,
            errors,
            touched,
            validateForm,
            values,
          }: FormikProps<FormikValues>) => {
            return (
              <>                
                {
                  isGoogleAccount &&
                  <>
                    <Box mt={2} />
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <Field name="hrBusiness" component={TextField} label="HR Business" />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6}>
                        <FormControl fullWidth margin="normal">
                          <Field name="source" component={TextField} label="Source" />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </>
                }
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <InformationSourceSelect fullWidth margin="normal" />
                  </Grid>
                </Grid>
              </>
            );
          }}
        </CreateForm>
      );
    }    
};

/**
 *
 */
const BusinessForm: FC = () => {
  const { onClose } = usePersonCreateContext();
  const [createBusinessObject] = useCreateBusinessObjectMutation();
  const [updateOrCreateContact] = useUpdateOrCreateContactMutation();    
  

  const create = async ({
    name,
    category,
    phones,
    firstName,
    lastName,
  }: Pick<BusinessObject, "name" | "category" | "phones"> &
    Pick<Person, "firstName" | "lastName">) => {
    const { data } = await createBusinessObject({
      variables: {
        businessObjectInput: {
          name,
          phones,
          category,
        },
        contactPersonInput: {
          firstName,
          lastName,
        },
      },
    });

    return data?.createBusinessObject?.id;
  };

  const update = async ({
    id,
    firstName,
    lastName,
  }: Pick<BusinessObject, "id"> & Pick<Person, "firstName" | "lastName">) => {
    const { data } = await updateOrCreateContact({
      variables: {
        id: id!,
        input: {
          firstName,
          lastName,
          phones: [],
          addresses: [],
          emails: [],
        },
      },
    });

    return data?.updateOrCreateContact?.id;
  };

  

  const onSubmit = async (values: FormikValues, { setSubmitting }: FormikActions<FormikValues>) => {
    const { contactPerson, countryCode, areaCode, number, name, id, businessCategoryId } = values;

    const phones = areaCode && number ? [{ areaCode, number, countryCode }] : [];
    const [lastName, ...firstName] = contactPerson.split(" ").reverse();

    let businessObjectId = id;
    if (!businessObjectId) {
      businessObjectId = await create({
        name,
        category: {
          id: businessCategoryId,
        },
        phones,
        firstName: firstName.reverse().join(" "),
        lastName,
      });
    } else {
      await update({
        id: businessObjectId,
        firstName: firstName.reverse().join(" "),
        lastName,
      });
    }    
    
    if (businessObjectId) {      
      onClose(`/business/${businessObjectId}`);
    } else {
      onClose(undefined, true);
    }

    setSubmitting(false);
  };

  return (
    <CreateForm
      title="Geschäftskontakt anlegen"
      onSubmit={onSubmit}
      initialValues={initialBusinessValues}
      validationSchema={BusinessValidation}
    >
      {({ setFieldValue, values }) => {
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  {values.name && <Field name="name" component={TextField} label="Name" />}
                  {!values.name && (
                    <AsyncSearch.BusinessObjectSearch
                      label="Name"
                      placeholder="Suche nach Name"
                      onChange={(businessObject: BusinessObject) => {
                        setFieldValue("id", businessObject.id);
                        setFieldValue("businessCategoryId", businessObject.category?.id);
                      }}
                      onCreateOption={(businessObjectName: string) => {
                        setFieldValue("name", businessObjectName);
                      }}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth margin="normal">
                  <Field name="contactPerson" component={TextField} label="Ansprechpartner" />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <PhoneCountrySelect name="countryCode" />
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <Field name="areaCode" component={TextField} label="Vorwahl" />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth margin="normal">
                  <Field name="number" component={TextField} label="Nummer" />
                </FormControl>
              </Grid>
            </Grid>
            {!values.id && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <BusinessCategorySelect
                    businessCategory={undefined}
                    setBusinessCategory={category => {
                      setFieldValue("businessCategoryId", category.value);
                    }}
                  />
                </Grid>
              </Grid>
            )}
          </>
        );
      }}
    </CreateForm>
  );
};

export default PersonCreateForm;
