import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { useAssignGMailMessageMutation, useFinishThreadMutation } from "../../../generated/graphql";
import { useEmailThreadContext } from "../../../providers/Email";
import { getWorkingAreaName } from "../../../utils/translateWorkingAreaEnums";
import EmailSuccessIllustration from "../../assets/EmailSuccessIllustration";
import getTeamName from "../../../utils/getTeamName";

import PrimaryButton from "../../atoms/PrimaryButton";
import AssignModal from "../../organisms/AssignDialog";
import TransferWpoClientDialog from "../../organisms/TransferWpoClientDialog";
import EmailThreadView from "./EmailThreadView";

const EmailThread = () => {
  const {
    context: { businessObject, person },
    personActivity,
    thread,
    emails,
  } = useEmailThreadContext();
  const { push } = useHistory();
  const [open, setOpen] = useState(false);
  const [openAssignClient, setAssignClientOpen] = useState(false);
  const [name, setName] = useState<string | undefined>(undefined);
  const [assignGMailMessage] = useAssignGMailMessageMutation({
    variables: {
      threadId: thread!.threadId!,
    },
  });

  const [finishThread] = useFinishThreadMutation({
    variables: {
      threadId: thread!.threadId!,
    },
  });

  console.log(personActivity)

  return (
    <>
      <EmailThreadView
        actions={{
          backAction: () => push("/emails"),
          assignAction: () => setOpen(true),
          assignClientAction: () => setAssignClientOpen(true),
          finishAction: () => {
            finishThread();
            if (businessObject) {
              push(`/business/${businessObject?.id}/memo`, {
                activity: personActivity,
              });
            } else if (person) {
              push(`/person/${person?.id}/report`, {
                activity: personActivity,
              });
            } else {
            }
          },
        }}
      />
      <AssignModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={async values => {
          try {
            const { data } = await assignGMailMessage({
              variables: {
                threadId: thread!.threadId!,
                ...(values.userIds !== -1 ? { userId: values.userIds } : {}),
                ...(values.team !== -1 ? { team: values.team } : {}),
              },
            });

            if (data?.assignGMailMessage?.user) {
              setName(data?.assignGMailMessage?.user.name || undefined);
            }
            if (data?.assignGMailMessage?.team) {
              setName(getTeamName(data.assignGMailMessage.team));
            }
          } catch (error) {
            // TODO!: Add error handling
            console.error(error);
          }
        }}
        showSuccessModal={!!name}
        successModal={
          <>
            <Box mb={3} textAlign="center">
              <Typography variant="h1">E-Mail zugewiesen an:</Typography>
              <Typography variant="h1">{name}</Typography>
            </Box>
            <EmailSuccessIllustration />
            <Box display="flex" mt={4} justifyContent="center">
              <PrimaryButton
                onClick={() => {
                  setOpen(false);
                  setName(undefined);
                }}
              >
                OK
              </PrimaryButton>
            </Box>
          </>
        }
      />

      <TransferWpoClientDialog
        open={openAssignClient}
        businessObjectId={businessObject?.id || -1}
        onClose={(path, error) => {
          setAssignClientOpen(false);
          if (error) {
            console.log({ error });
          } else if (path) {
            push(path);
          }
        }}
      />

      {/* <AssignClientModal
        open={openAssignClient}
        onClose={() => setAssignClientOpen(false)}
        onSubmit={async values => {
          const { data } = await assignGMailMessage({
            variables: {
              threadId: thread!.threadId!,
              ...(values.userIds > -1 ? { userId: values.userIds } : {}),
              ...(values.workingAreaId > -1 ? { workingAreaId: values.workingAreaId } : {}),
            },
          });

          if (data?.assignGMailMessage?.user) {
            setName(data?.assignGMailMessage?.user.name || undefined);
          }
          if (data?.assignGMailMessage?.group) {
            setName(getWorkingAreaName(data?.assignGMailMessage?.group.name));
          }
        }}
        showSuccessModal={!!name}
        successModal={
          <>
            <Box mb={3} textAlign="center">
              <Typography variant="h1">E-Mail zugewiesen an:</Typography>
              <Typography variant="h1">{name}</Typography>
            </Box>
            <EmailSuccessIllustration />
            <Box display="flex" mt={4} justifyContent="center">
              <PrimaryButton
                onClick={() => {
                  setAssignClientOpen(false);
                  setName(undefined);
                }}
              >
                OK
              </PrimaryButton>
            </Box>
          </>
        }
      /> */}
    </>
  );
};

export default EmailThread;
