import React, { useState, FC } from "react";
import { useMutation } from "@apollo/client";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

import { TextField, Select } from "formik-material-ui";
import { Formik, Field, FormikProps, FormikValues } from "formik";

import {
  Person,
  Client,
  UpdateClientDocument,
  AccountType,
  SourceOfInformation,
  BreadCrumbInfoByPersonIdDocument,
} from "../../../generated/graphql";
import PrimaryButton from "../../atoms/PrimaryButton";
import DataColumn from "../../molecules/DataColumn";
import DataRow from "../../molecules/DataRow";
import DefaultDialog from "../../molecules/DefaultDialog";
import DialogContent from "../../molecules/DefaultDialogContent";
import SecondaryButton from "../../atoms/SecondaryButton";
import useStyles from "../../forms/formStyles";
import LanguageSelect from "../../molecules/LanguageSelect";

export const SOURCE_OF_INFORMATION_OPTIONS: {
  [key in keyof typeof AccountType]: {
    value: SourceOfInformation;
    label: string;
  }[];
} = {
  [AccountType.lifeWorks]: [
    { value: SourceOfInformation.previousClient, label: "Caller was a Previous Client" },
    { value: SourceOfInformation.coWorker, label: "Co-worker" },
    { value: SourceOfInformation.familyMember, label: "Family Member" },
    { value: SourceOfInformation.hr, label: "Human Resources" },
    { value: SourceOfInformation.informationBooth, label: "Information Booth" },
    { value: SourceOfInformation.internalCommunication, label: "Internal Communication" },
    { value: SourceOfInformation.eapMobileApp, label: "My EAP Mobile Application" },
    { value: SourceOfInformation.orientation, label: "Orientation" },
    {
      value: SourceOfInformation.peerSupportOrWellnessBureau,
      label: "Peer Support Team/Wellness Bureau",
    },
    { value: SourceOfInformation.promotionalLiterature, label: "Promotional Literature" },
    { value: SourceOfInformation.supervisor, label: "Supervisor/Manager" },
    { value: SourceOfInformation.traumaDebriefing, label: "Trauma Debriefing" },
    { value: SourceOfInformation.unionRepresentative, label: "Union Representative" },
    { value: SourceOfInformation.webIntake, label: "Web Intake" },
    { value: SourceOfInformation.websiteViewing, label: "Website Viewing" },
    { value: SourceOfInformation.wellnessSeminar, label: "Wellness Seminar" },
    { value: SourceOfInformation.healthServices, label: "Worksite Health Services" },
    { value: SourceOfInformation.DECLINED, label: "Declined" },
  ],
  [AccountType.assistance]: [],
  [AccountType.eap]: [],
  [AccountType.chesnut]: [],
  [AccountType.international]: [],
  [AccountType.morneauShepell]: [],
  [AccountType.test]: [],
  [AccountType.wpo]: [],
};

const Other: FC<{ person: Person }> = ({ person }) => {
  const [open, setOpen] = useState(false);
  
  const [clientUpdate] = useMutation(UpdateClientDocument, {
    refetchQueries: [{ query: BreadCrumbInfoByPersonIdDocument, variables: { id: person.id } }],
  });

  const handleSubmit = async (
    values: Pick<
      Client,
      "id" | "language" | "contactLanguageId" | "healthInsuranceCompany" | "sourceOfInformation" | "hrBusiness" | "source"
    >,
  ) => {
    return clientUpdate({
      variables: {
        clientInput: {
          id: values.id,
          language: values.language || null,
          healthInsuranceCompany: values.healthInsuranceCompany || null,
          sourceOfInformation: values.sourceOfInformation || null,
          contactLanguageId: values.contactLanguageId || null,
          hrBusiness: values.hrBusiness || '',
          source: values.source || '',
        },
      },
    });
  };

  const client = person.client;

  if (!client) {
    return <></>;
  }

  const sourceOfInformationOptions = client.account?.type
    ? SOURCE_OF_INFORMATION_OPTIONS[client.account.type]
    : [];

  const sourceOfInformationLabel =
    sourceOfInformationOptions.find(source => source.value === client.sourceOfInformation)?.label ??
    "-";

  const getLanguage = () => {
    if (person.client?.contactLanguage) {
      if (person.client.contactLanguage.code !== "de") {
        return (
          <>
            <Typography variant="body2">{person.client.contactLanguage.name}</Typography>
            <Typography variant="body2">{person.client.language || "-"}</Typography>
          </>
        );
      }
    }

    return <Typography variant="body2">{client.language || "-"}</Typography>;
  };
  
  let isLifeWorkAccount = false;
  if (client.account?.type) {
    if (client.account.type === AccountType.lifeWorks) {
      isLifeWorkAccount = true;
    }
  }
  let isGoogleAccount = false;
  if (client.account?.name) {
    isGoogleAccount = client.account.name.toUpperCase().includes('GOOGLE');
  }

  return (
    <>
      <DataRow title="Sonstiges" editTrigger={() => setOpen(true)}>
        <DataColumn label="Sprache">{getLanguage()}</DataColumn>

        <DataColumn label="Krankenkasse">
          <Typography variant="body2">{client.healthInsuranceCompany || "-"}</Typography>
        </DataColumn>

        {sourceOfInformationOptions.length > 0 ? (
          <DataColumn label="Source of information">
            <Typography variant="body2">{sourceOfInformationLabel}</Typography>
          </DataColumn>
        ) : null}

        {
          isLifeWorkAccount && isGoogleAccount &&
          <>
            <DataColumn label="HR Business">
              {client.hrBusiness || "-"}
            </DataColumn>

            <DataColumn label="Source">
              <Typography variant="body2">
                {client.source || "-"}
                </Typography>
            </DataColumn>
          </>
        }
      </DataRow>

      <DefaultDialog open={open} onClose={() => setOpen(false)}>
        <DialogContent>
          <Formik
            enableReinitialize
            onSubmit={async (values, { setSubmitting }) => {
              // @ts-ignore
              await handleSubmit(values);
              setSubmitting(false);
              setOpen(false);
            }}
            initialValues={{
              id: client.id,
              contactLanguageId: client.contactLanguage?.id || "",
              language: client.language ?? "",
              healthInsuranceCompany: client.healthInsuranceCompany ?? "",
              hrBusiness: client.hrBusiness ?? "",
              source: client.source ?? "",
              sourceOfInformation: client.sourceOfInformation ?? "",
            }}
          >
            {props => (
              <OtherForm
                {...props}
                cancel={() => setOpen(false)}
                sourceOfInformationOptions={sourceOfInformationOptions}
                isGoogleAccount={isGoogleAccount}
              />
            )}
          </Formik>
        </DialogContent>
      </DefaultDialog>
    </>
  );
};

interface OtherFormProps {
  cancel: () => void;
  sourceOfInformationOptions: { value: SourceOfInformation; label: string }[];
  isGoogleAccount: boolean;
}

const OtherForm: FC<OtherFormProps & FormikProps<FormikValues>> = ({
  cancel,
  handleSubmit,
  sourceOfInformationOptions,
  isGoogleAccount = false,
}) => {
  const classes = useStyles();  
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Grid container spacing={2} style={{ marginBottom: 8 }}>
        <Grid item xs={12}>
          <Typography variant="h1" align="center">
            Sonstiges
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <LanguageSelect tabIndex={1} />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field
              name="language"
              label="Weitere Sprachen"
              component={TextField}
              tabIndex={2}
              autoFocus
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth margin="normal">
            <Field
              name="healthInsuranceCompany"
              label="Krankenkasse"
              component={TextField}
              tabIndex={3}
            />
          </FormControl>
        </Grid>
      </Grid>

      {
        isGoogleAccount &&
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="hrBusiness"
                  label="HR Business"
                  component={TextField}
                  tabIndex={4}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <Field
                  name="source"
                  label="Source"
                  component={TextField}
                  tabIndex={5}
                />
              </FormControl>
            </Grid>
          </Grid>
        </>
      }

      {sourceOfInformationOptions.length > 0 ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="clientType">Source of Information</InputLabel>
              <Field name="sourceOfInformation" component={Select}>
                <MenuItem value={""}>-</MenuItem>

                {sourceOfInformationOptions.map(source => (
                  <MenuItem key={source.value} value={source.value}>
                    {source.label}
                  </MenuItem>
                ))}
              </Field>
            </FormControl>
          </Grid>
        </Grid>
      ) : null}

      <Box marginTop={4} display="flex" justifyContent="center" flexDirection="row-reverse">
        <PrimaryButton type="submit" tabIndex={0}>
          OK
        </PrimaryButton>
        <Box mx={1}></Box>
        <SecondaryButton tabIndex={0} onClick={cancel}>
          Abbrechen
        </SecondaryButton>
      </Box>
    </form>
  );
};
export default Other;
