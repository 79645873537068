import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Chip from "@material-ui/core/Chip";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import Typography from "@material-ui/core/Typography";
import MentalIcon from "../../atoms/MentalIcon";
import Name from "../../atoms/Name";
import { MentalState } from "../../../generated/graphql";
import useStyles from "./styles";

type TSuggestionList = {
  suggestions: Array<any> | null;
  suggestionSelect: (suggestion: any) => void;
};

const SuggestionList = ({ suggestions, suggestionSelect }: TSuggestionList) => {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      {suggestions &&
        suggestions.map(suggestion => {
          return (
            <ListItem
              className={classes.item}
              key={suggestion.id}
              button
              TouchRippleProps={{ classes: { child: classes.ripple } }}
              onMouseDown={() => suggestionSelect(suggestion.id)}
              onKeyDown={e => {
                if (e.which === 13) {
                  suggestionSelect(suggestion.id);
                }
              }}
            >
              <Chip
                className={classes.chip}
                color="primary"
                size="small"
                label={suggestion.avatarKeys}
                avatar={
                  <MentalIcon
                    type={suggestion.mentalState || MentalState.neutral}
                    className={classes.mental}
                  />
                }
              />
              <ListItemText>
                <Name>
                  {suggestion.name}
                  {Boolean(suggestion.age) &&
                    `, ${suggestion.age}${suggestion.ageGuessed ? " (geschätzt)" : ""} Jahre`}
                </Name>
              </ListItemText>
              <ListItemSecondaryAction className={classes.secondary}>
                <Typography variant="body2">{suggestion.note}</Typography>
              </ListItemSecondaryAction>              
            </ListItem>
          );
        })}
    </List>
  );
};

export default SuggestionList;
