import React, { FC, SyntheticEvent, useContext, useState } from "react";
import { useHistory, useLocation } from "react-router";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Trash from "@material-ui/icons/Delete";

import classnames from "classnames";

import ListItemText from "@material-ui/core/ListItemText";
import CallDialog from "../CallDialog";
import Box from "@material-ui/core/Box";

import {
  PersonActivity,
  MeQuery,
  ServiceType,
  ServiceWizardStatus,
  useDeleteActivityMutation,
} from "../../../generated/graphql";
import { useAuthorization } from "../../../providers/AuthorizationProvider";
import TypeIcon from "../../atoms/TypeIcon";

import BubbleType from "./BubbleType";
import { getTargetAndState } from "./Bubbles/InteractionBubble";
import { ActivitiesStreamContext } from ".";
import useStyles from "./styles";
import "./styles.css";

type TBubble = {
  activity: PersonActivity;
  meData?: MeQuery["me"];
  topicId?: number;
  personId: number | undefined;
  clientBubble?: boolean;
};

export const Bubble: FC<TBubble> = ({ activity, meData, personId, topicId }) => {
  const classes = useStyles();
  const [deleteActivity] = useDeleteActivityMutation();
  const [open, setOpen] = useState(false);
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { context } = useContext(ActivitiesStreamContext);
  const { isAuthorized } = useAuthorization();

  let service, state: any, target: string | undefined;

  switch (activity.activityType) {
    case "sms":
      service = activity.service!;
      break;
    case "service":
      service = activity.service!;

      if (service.wizardStatus === ServiceWizardStatus.incomplete) {
        if (meData?.id === activity?.author?.id) {
          target = `/person/${personId}/service/${service.id}/setup`;
        }
      } else {
        if (service.serviceMeta?.type !== ServiceType.internalConsult) {
          target = `/service/${service.id}`;
        } else {
          target = `/internalConsult/${service.id}`;
        }
      }

      break;
    case "relationship":
    case "person":
      if (context !== "person") {
        // Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
        if (activity?.person?.businessIds?.length) {
          target = `/business/${activity.person.businessIds[0]}/contacts/${personId}`;
        } else {
          target = `/person/${personId}`;
        }
      }
      break;
    case "mandate":
      service = activity.service!;
      target = `/externalConsult/${service.id}`;
      break;
    case "cmCheck":
      if (pathname !== `/person/${personId}/caseManagement`) {
        target = `/person/${personId}/caseManagement`;
      }
      break;
    case "driveDocument":
      break;
    case "callOut":
    case "callIn":
    case "mail":
    case "chat":
    case "wpoVerweisung":
      const interactionPath = getTargetAndState({
        activity,
        userId: meData?.id,
        pathname,
        personId,
        topicId,
      });
      target = interactionPath.target;
      state = interactionPath.state;
      break;
  }

  const onDeleteClick = async (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (window.confirm("Wollen sie die Aktivität wirklich löschen?")) {
      await deleteActivity({ variables: { id: activity.id } });
    }
  };

  const onClick = !!target
    ? () => {
      if (["callOut", "callIn"].includes(activity.activityType || "") && activity.tempPerson) {
        setOpen(true);
        return;
      }

      push({ pathname: target, state });
    }
    : undefined;

  
  return (
    <>
      <ListItemText
        className={classnames(classes.listItemRedesign, {
          [classes.clickable]: onClick !== undefined,
          [classes.active]: activity.isActive,
        })}
        disableTypography
        onClick={onClick}
      >
        <Box
          className={classnames(classes.typeIcon, {
            [classes.activeText]: activity.isActive,
          })}
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingX={2}
        >
          {// Due to refactoring: https://medium.com/@calebmer/when-to-use-graphql-non-null-fields-4059337f6fc8
            activity.activityType && <TypeIcon type={activity.activityType} />}
        </Box>
        <Box paddingY={1} flexGrow={1} paddingRight={2}>
          <BubbleType
            isLink={onClick !== undefined && !activity.isActive && true}
            activity={activity}
          />
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          paddingRight={2}
          flexDirection="column"
        >
          {/* {onClick !== undefined && !activity.isActive && (
            <ArrowForwardIos color="primary" fontSize="small" />
          )} */}
          {isAuthorized("deleteActivity") && (
            <Trash
              color="primary"
              fontSize="small"
              onClick={onDeleteClick}
              className={classes.trash}
            />
          )}
        </Box>
      </ListItemText>
      {["callOut", "callIn"].includes(activity.activityType || "") && activity.tempPerson ? (
        <CallDialog
          activityId={activity.id}
          open={open}
          handleClose={() => setOpen(false)}
          pushTarget={personId => `/person/${personId}/report`}
        />
      ) : null}
    </>
  );
};

export default Bubble;
