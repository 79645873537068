import { useEffect, useState } from "react";

import { DocumentNode } from "graphql";
import levenshtein from "fast-levenshtein";

import { PersonTypes } from "../../atoms/PersonTypeIcon";
import { client } from "../../App";
import { OptionValue } from "./components";

interface ExtendedOptionValue extends OptionValue {
  distance: number;
}

export const computeLevenshteinDistance = (option: OptionValue, name: string) => {
  return {
    ...option,
    distance: levenshtein.get(option!.name, name),
  };
};

export const sortByDistance = (a: ExtendedOptionValue, b: ExtendedOptionValue) => {
  return a.distance - b.distance;
};

export const sortClientFirst = (a: ExtendedOptionValue, b: ExtendedOptionValue) => {
  // @ts-ignore
  if (a.type === PersonTypes.client && b.type !== PersonTypes.client) {
    return -1;
  }
  // @ts-ignore
  if (b.type === PersonTypes.client && a.type !== PersonTypes.client) {
    return 1;
  }
  return 0;
};

const useDebouncedSearch = (
  debounceMs: number,
  queryOptions: {
    query: DocumentNode;
    variables?: { [key: string]: string | number | boolean | undefined };
    fetchPolicy?: "network-only";
  },
  formatData: (name: string, data: any) => any,
  setResult?: (result: any) => void,
) => {
  const [timeouts, setTimeouts] = useState<any[]>([]);

  const loadOptions = (name: string, callback: any) => {
    const trimmedName = name.trim();

    if (trimmedName.length < 3) return callback(undefined);

    const timeout = setTimeout(async () => {
      const result = await client.query({
        ...queryOptions,
        variables: { ...queryOptions.variables, name: trimmedName },
      });

      if (setResult) {
        setResult(result.data);
      }

      callback(formatData(trimmedName, result.data));
    }, debounceMs);

    setTimeouts(timeouts => [...timeouts, timeout]);
  };

  useEffect(() => {
    if (timeouts.length > 1) {
      clearTimeout(timeouts.reverse().pop());
    }
  }, [timeouts]);

  return loadOptions;
};

export const useDebouncedSearchForCallDialog = (
  debounceMs: number,
  queryOptions: {
    query: DocumentNode;
    variables?: { [key: string]: string | number | boolean | undefined };
    fetchPolicy?: "network-only";
  },
  formatData: (name: string, data: any) => any,
  setResult?: (result: any) => void,
) => {
  const [timeouts, setTimeouts] = useState<any[]>([]);

  const loadOptions = (name: string, callback: any) => {
    const trimmedName = name.trim();

    if (trimmedName.length < 3) return callback(undefined);

    const timeout = setTimeout(async () => {
      const result = await client.query({
        ...queryOptions,
        variables: { ...queryOptions.variables, name: trimmedName },
      });

      if (setResult) {
        if (result.data?.clientSearch) {
          setResult(result.data?.clientSearch?.persons);
        } else {
          setResult([])
        }
      }

      callback(formatData(trimmedName, result.data));
    }, debounceMs);

    setTimeouts(timeouts => [...timeouts, timeout]);
  };

  useEffect(() => {
    if (timeouts.length > 1) {
      clearTimeout(timeouts.reverse().pop());
    }
  }, [timeouts]);

  return loadOptions;
};


export default useDebouncedSearch;
