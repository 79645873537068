import React, { useState } from "react";

import Async from "react-select/async";
import AsyncCreatableSelect from "react-select/async-creatable";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import NoSsr from "@material-ui/core/NoSsr";
import Box from "@material-ui/core/Box";

import { Person, PersonSearchWithClientDocument, PersonSearchWithClientQuery } from "../../../../generated/graphql";
import { PersonTypes } from "../../../atoms/PersonTypeIcon";

import useDebouncedSearch from "../hooks";
import useStyles, { CustomComponents, OptionValue } from "../components";

const formatData = (name: string, data?: PersonSearchWithClientQuery) => {
  const results = data?.personSearchWithClient;

  if (!results) return [];
  
  return results.persons?.map(formatPerson) || [];
};

type TPersonSearchPerson = {
  __typename?: "Person";
  id: number;
  name: string;
  age?: number | null;
  ageGuessed?: boolean | null;
  businesses?: Array<{
    __typename?: "BusinessObject";
    id?: number | null;
    name?: string | null;
  } | null> | null;
  client?: {
    __typename?: "Client";
    id?: number | null;
    account?: {
      __typename?: "Account";
      id?: number | null;
      name?: string | null;
      contractEnd?: any | null;
    } | null;
  } | null;
};

export const formatPerson = (person?: TPersonSearchPerson | null): OptionValue => {
  if (!person) {
    // @ts-ignore
    return {};
  }

  let path = `/person/${person.id}`;
  if (!!person.client) {
    let label = person.name;
    if (person.age) {
      label = `${label}, ${person.ageGuessed ? "ca. " : ""}${person.age} J`;
    }
    return {
      type: PersonTypes.client,
      label: `${label}, ${person.client!.account?.name || "ohne Account"}`,
      name: `${person.name}`,
      path,
      value: person.id,
      expirationDate: person.client?.account?.contractEnd,
    };
  }

  let label = person.name || "";
  let type = PersonTypes.person;

  if (person.businesses) {
    const business = person.businesses[0];
    if (business) {
      type = PersonTypes.businessContact;
      label = `${label} (${business.name})`;
      path = `/business/${business.id}/contacts/${person.id}`;
    }
  }

  if (person.age) {
    label = `${label}, ${person.ageGuessed ? "ca. " : ""}${person.age} J`;
  }

  return {
    type,
    label,
    path,
    name: `${person.name}`,
    value: person.id,
  };
};

type TPersonSearch = {
  autoFocus?: boolean;
  onClick: (selection: any) => void;
  onCreateOption?: (inputValue: string) => void;
  person?: Person | null;
  placeholder?: string;
};

const PersonWithClientSearch = ({
  autoFocus = true,
  onClick,
  person,
  onCreateOption,
  placeholder,
}: TPersonSearch) => {
  const classes = useStyles();
  const [value, setValue] = useState<OptionValue | null>(formatPerson(person));
  const loadOptions = useDebouncedSearch(
    300,
    {
      query: PersonSearchWithClientDocument,
    },
    formatData,
  );

  return (
    <Box position="relative">
      <NoSsr>
        {!!onCreateOption && (
          <AsyncCreatableSelect
            autoFocus            
            classes={classes}            
            components={{
              ...CustomComponents,
              DropdownIndicator: null,
              ClearIndicator: null,
            }}       
            menuPosition="fixed"     
            createOptionPosition={"first"}
            formatCreateLabel={(inputValue: string) => `“${inputValue}” neu anlegen`}
            loadOptions={loadOptions}
            onChange={(selection: any) => {
              if (selection) {
                // @ts-ignore
                setValue(selection);
                // @ts-ignore
                onClick(selection.value);
              }
            }}
            onCreateOption={onCreateOption}
            placeholder={placeholder}
          />
        )}
        {!onCreateOption && (
          <Async
            // Force component to rerender if value changes
            key={String(value)}
            escapeClearsValue
            autoFocus={autoFocus}
            classes={classes}
            menuPosition="fixed"
            components={{
              ...CustomComponents,
              DropdownIndicator: null,
              ClearIndicator: null,
            }}
            isClearable
            loadOptions={loadOptions}
            // @ts-ignore
            onChange={selection => {
              if (selection) {
                // @ts-ignore
                setValue(selection);
                // @ts-ignore
                onClick(selection.value);
              }
            }}
            placeholder="Suche"
            value={!!value?.label ? value : undefined}
          />
        )}
      </NoSsr>
      {!!value?.label && (
        <Box
          position="absolute"
          right="0"
          top="2px"
          onClick={() => {
            setValue(null);
            onClick(-1);
          }}
        >
          <IconButton aria-label="delete" size="small">
            <CloseIcon fontSize="small" color="primary" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default PersonWithClientSearch;
