import React, { FC } from "react";

import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import orderBy from "lodash/orderBy";
import classnames from "classnames";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Flag from "@material-ui/icons/Flag";
import StarRate from "@material-ui/icons/StarRate";

import EmptyIcon from "../../assets/EmptyIcon";
import { useHotFlagsQuery, Client } from "../../../generated/graphql";
import { fetchPolicy } from "../../../utils/constants";
import useStyles from "./styles";

const HotTopics: FC = () => {
  dayjs.extend(LocalizedFormat);
  const { data, loading } = useHotFlagsQuery({ fetchPolicy });
  const classes = useStyles();
  const hotFlags = (data?.hotFlags || []) as Client[];
  return !loading ? (
    <Box marginTop={2}>
      <Typography variant="h4">Aktuelle Hot Topics</Typography>
      <Box marginTop={2}>
        {hotFlags.length > 0 ? (
          orderBy(hotFlags, ["person.redFlag", "person.yellowFlag"]).map((c, idx) => {
            const name = c.person?.name || "";
            const id = c.person?.id;
            const account = c.account?.name || "";
            const red = c.person?.redFlag || false;
            const yellow = c.person?.yellowFlag || false;
            const nameTag = `${name}${
              account.length ? `${name.length ? `, ${account}` : account}` : ""
            }`;

            return (
              !loading && (
                <Box key={idx} display="flex" paddingY={1}>
                  {(red || yellow) && (
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="baseItems"
                      paddingTop="3px"
                      paddingRight={2}
                      className={classnames({
                        [classes.red]: red,
                        [classes.yellow]: yellow,
                      })}
                    >
                      <Flag></Flag>
                    </Box>
                  )}
                  {!(red || yellow) && (
                    <Box
                      alignItems="baseline"
                      display="flex"
                      justifyContent="center"
                      paddingRight={2}
                    >
                      <StarRate htmlColor="#5AA6E2"></StarRate>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="column" justifyContent="center">
                    <Link
                      to={`/person/${id}`}
                      variant="h4"
                      underline="always"
                      component={RouterLink}
                    >
                      {nameTag}
                    </Link>
                  </Box>
                </Box>
              )
            );
          })
        ) : (
          <Box display="flex" alignItems="center" mt={3}>
            <Box height={8 * 8} mr={3}>
              <EmptyIcon></EmptyIcon>
            </Box>
            <Typography variant="body2">Es gibt derzeit keine Hot Topics</Typography>
          </Box>
        )}
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default HotTopics;
